<template>
  <div class="pagination-container">
    <ul class="pagination-wrap">
      <li @click="goPage(1)"><i class="fas fa-angle-double-left"></i></li>
      <li @click="goPage(pageNum-1)"><i class="fas fa-angle-left"></i></li>
      <template v-for="i in navArray" :key="`nav${i}`">
        <li :class="{ active: pageNum === i }" @click="goPage(i)">{{ i }}</li>
      </template>
      <li @click="goPage(pageNum+1)"><i class="fas fa-angle-right"></i></li>
      <li @click="goPage(navMax)"><i class="fas fa-angle-double-right"></i></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    pageNum: {
      type: Number,
      default: 1,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true
    },
    navSize: {
      type: Number,
      default: 10
    }
  },
  computed: {
    navArray () {
      return new Array(Math.max(this.navMin, this.navEnd - this.navStart + 1))
        .fill(this.navStart).map((n, i) => n + i)
    },
    navStart () {
      return Math.max(this.pageNum - (this.navSize - 1 - Math.min(Math.floor(this.navSize / 2),
        this.navMax - this.pageNum)), this.navMin)
    },
    navEnd () {
      return Math.min(this.pageNum + Math.max(Math.floor(this.navSize / 2),
        this.navSize - this.pageNum), this.navMax)
    },
    navMin () {
      return 1
    },
    navMax () {
      return Math.floor((this.totalCount - 1) / this.pageSize) + 1
    }
  },
  methods: {
    goPage(no) {
      this.$emit("goPage", no);
    }
  }
};
</script>

<style scoped>
.pagination-container {
  margin: 20px 0 50px;
}
.pagination-wrap {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  overflow: hidden;
  font-size: 18px;
  gap: 10px;
}
.pagination-wrap > li {
  color: #353535;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap > li.active {
  color: #fff;
  background: #d93832;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.pagination-wrap > li:hover:not(.active) {
  color: #2fa8d7;
  font-weight: 800;
}
</style>
