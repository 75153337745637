<template>
  <input :type="type" :placeholder="placeholder" :class="className" @keyup="onKeyup" v-model="model" />
</template>

<script>
export default {
  name: 'UiTyping',
  props: ['placeholder', 'type', 'className'],
  emits: [
    'input'
  ],
  data () {
    return {
      model: ''
    }
  },
  watch: {
    modelValue (value) {
      this.model = value
    }
  },
  created () {
    if (this.modelValue) {
      this.model = this.modelValue
    }
  },
  methods: {
    onKeyup () {
      this.$emit('input', this.model)
    }
  }
}
</script>

<style scoped>
input{
  font-size: 13px;
  border: 1px solid #bfbfbf;
  height:22px;
  padding:5px 6px;
  box-sizing: border-box;
  margin-right:4px;
}
input:last-child{
  margin-right:0;
}
</style>
