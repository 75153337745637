<template>
  <div class="search-filter">
    <template v-if="srchFiltersProp.selectOptions" >
    <ui-select :selectOptions="srchFiltersProp.selectOptions" :inSrch="false" @change="onSelect"/>
    </template>
    <ui-typing :placeholder="srchFiltersProp.placeholder" />
    <button class="btn-search" type="button" @click="pageSeach">
      <i class="fas fa-search"></i>
    </button>
  </div>
</template>

<script>
import UiSelect from '@/components/ui/Select'
import UiTyping from '@/components/ui/Typing'

export default {
  name: 'SearchFilter',
  components: {
    UiSelect,
    UiTyping
  },
  props: ['srchFiltersProp'],
  data(){
    return{
      text:"",
      select:""
    }
  },
  methods:{
    onSelect(value,event){
      console.log(event)
      console.log(value)
    }
  }
}
</script>

<style scoped>
.search-filter{
  display: flex;
  align-content: center;
}
.search-filter > *:not(button){
  margin-right:5px;
}
button {
  height: 26px;
  background: #18698b;
  color: #fff;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  font-size:0.813em;
  border-radius: 2px;
  padding: 0 5px;
}

button:hover {
 background:#1f4986;
}
button i{
  margin-right:5px;
}
input[type="text"]{
  width:150px;
}
</style>
