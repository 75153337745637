<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div :class="'searchbar-content'">
           <div class="title"><span>{{ $t('searchArea.sch') }}</span></div>
           <div>
             <select class="mr-5" v-model="reqData.partnerType">
               <option value="">{{ $t('searchArea.entire') }}</option>
               <option value="master" v-if="siteInfo.partnerType === 'senior'">{{ $t('company.master') }}</option>
               <option value="agency">{{ $t('company.agency') }}</option>
             </select>
             <select class="mr-5" v-model="searchOption.type">
               <option value="name">{{ $t('company.companyName') }}</option>
               <!-- <option value="id">{{ $t('table.head.id') }}</option> -->
             </select>
             <input type="text" class="mr-5" v-model="searchOption.text" />
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody class="family-wrap" v-for="(item, index) in companyList" :key="index">
            <tr class="row-parent">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.memId)">{{ item.memId }}</button>
              </td>
              <td>{{ item.memName }}</td>
              <td>{{ $t(item.partnerType) }}</td>
              <td>{{ item.siteDomain }}</td>
              <td>{{ numberWithCommas(item.totalMemCnt) }}</td>
              <td>{{ numberWithCommas(item.totalCashAmt) }}</td>
              <td>{{ numberWithCommas(item.totalPointAmt) }}</td>
              <td>{{ numberWithCommas(item.creditAmt) }}</td>
              <td>{{ numberWithCommas(item.maxCredit) }}</td>
              <td>{{ Number(item.creditRate).toFixed(2) }}%</td>
              <td style="width: 15%">{{ item.redDt }}</td>
            </tr>
            <!-- <tr class="row-child">
            <td>
            </td>
          </tr> -->
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="companyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import { childOpen } from '@/assets/js/common.js'
import SearchFilter from '@/components/common/SearchFilter'
import TableHead from '@/components/main/table/Head.vue'
import UiSelect from '@/components/ui/Select'
import UiCheck from '@/components/ui/UiCheckSet'
import { companyList, companyTreeList, companyDetail } from '@/api/member.js'
import Pagination from '@/components/common/Pagination'
import { PARTNER_TYPE } from '@/libs/constants.js'
export default {
  name: 'CompanyList',
  components: {
    SearchFilter,
    TableHead,
    Pagination
  },
  data: function () {
    return {
      modalOn: false,
      toggle: false,
      parentList: ['gktest'],
      classes: {
        open: 'fa-minus',
        close: 'fa-plus'
      },
      headInfo: {
        fstColumn: false,
        dataList: ['id', 'companyName', 'companyType', 'domain', 'userCnt', 'cashAmt', 'pointAmt', 'ptAmt', 'maxPT', 'ptFeeRate', 'regDate']
      },
      companyList: [],
      treeData: [],
      pageInfo: {},
      reqData: {
        partnerType: '',
        siteId: '',
        memId: '',
        memName: '',
        page: 1,
        count_per_list: 30
      },
      siteInfo: {},
      searchOption: {
        type: 'name',
        text: ''
      }
    }
  },
  methods: {
    openWindow () {
      window.open('/user/userDetail', 'userDetail', 'top=100,left=150,scrollbars=no,resizable=no,width=1900,height=890,status=no')
    },
    async getTreeList (memId) {
      const reqData = {
        targetId: memId,
        searchType: 'partner'
      }
      return await companyTreeList(reqData)
    },
    childDataConverter (childDatas, parentDepth) {
      const replaceData = []
      for (const item of childDatas) {
        const obj = {
          depth: parentDepth + 1,
          toggle: false
        }
        for (const key in item) {
          if (key === 'memid') {
            obj.memId = item.memid
          } else {
            obj[key] = item[key]
          }
        }
        (item.companyType = '-'), (item.recommenderId = '-')
        item.redDt = '-'
        replaceData.push(obj)
      }
      return replaceData
    },
    async setChildTree (memId, targetIdx) {
      const res = await this.getTreeList(memId)
      const childTreeList = res.data.list
      let parentObj = {}
      let parentIndex = 0
      if (childTreeList.length === 0) {
        return
      }
      for (let i = targetIdx; i < this.companyList.length; i++) {
        const item = this.companyList[i]
        if (item.memId === memId) {
          parentObj = item
          parentIndex = i
          item.toggle = !item.toggle
          break
        }
      }
      // console.log(parentIndex)
      let childData = []
      childData = this.childDataConverter(childTreeList, parentObj.depth)
      // console.log(childData);
      for (const item of childData) {
        this.companyList.splice(parentIndex + 1, 0, item)
      }
      console.log(this.companyList)
    },
    delChildTree (memId, targetIdx) {
      let parentObj = {}
      let parentIndex = 0
      for (let i = targetIdx; i < this.companyList.length; i++) {
        const item = this.companyList[i]
        if (item.memId === memId) {
          parentObj = item
          parentIndex = i + 1
          item.toggle = !item.toggle
          break
        }
      }
      console.log(parentIndex)
      let delCount = 0
      for (let i = parentIndex; i < this.companyList.length; i++) {
        const item = this.companyList[i]
        console.log(item)
        const parentDepth = parentObj.depth

        if (item.depth > parentDepth) {
          delCount++
        } else {
          break
        }
      }
      this.companyList.splice(parentIndex, delCount)
      console.log(this.companyList)
    },
    onClickTree (item, targetIdx) {
      const memId = item.memId
      if (item.toggle) {
        this.delChildTree(memId, targetIdx)
      } else {
        this.setChildTree(memId, targetIdx)
      }
    },
    async getCompanyList () {
      const data = this.reqData
      return await companyList(data)
    },
    async pageSeach () {
      const searchType = this.searchOption.type
      const text = this.searchOption.text
      if (searchType === 'id') {
        this.reqData.memId = text
        this.reqData.memName = ''
      } else if (searchType === 'name') {
        this.reqData.memName = text
        this.reqData.memId = ''
      }
      await this.setTableData()
    },
    async setTableData () {
      // 파트너 데이터 재정리
      this.emitter.emit('Loading', true)
      const res = await this.getCompanyList()
      this.emitter.emit('Loading', false)
      console.log(res)
      const pageInfo = res.data.pageInfo
      this.pageInfo = pageInfo
      console.log(pageInfo)
      const companyList = res.data.list
      console.log(companyList)
      companyList.forEach(items => {
        items.companyType = PARTNER_TYPE[items.partnerType]
        for (const item in items) {
          if (items[item] !== 0) {
            if (!items[item]) {
              items[item] = '-'
            }
          }
        }
        if (!items.creditRate) {
          items.creditRate = 0
        }
        items.depth = 0
        items.toggle = false
      })
      console.log(companyList)
      this.companyList = companyList
      // console.log(this.companyList)
      // console.log(pageInfo)
    },
    async getUpperCompany () {
      const siteInfo = this.getSiteInfo()
      const reqDetail = {
        siteId: siteInfo.siteId,
        memId: siteInfo.siteId
      }
      const loginCompanyDetail = await companyDetail(reqDetail)
      // console.log(loginCompanyDetail.data.memberDetail);
      return await loginCompanyDetail.data.memberDetail
    }
  },
  async created () {
    this.siteInfo = await this.getUpperCompany()
    this.setTableData()
  }
}
</script>

<style scoped>
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
</style>
