<template>
  <thead>
    <tr>
      <th v-if="headInfo.fstColumn" class="funcHead" :style="headInfo.style"></th>
      <template v-if="Array.isArray(headInfo.dataList)">
        <th v-for="(text,index) in headInfo.dataList" :key="index">{{$t(`table.head.${text}`)}}</th>
      </template>
      <template v-if="!Array.isArray(headInfo.dataList)">
        <th v-for="(option,key) in headInfo.dataList" :style="option?{width:option+'%'}:''" :key="key">{{$t(`table.head.${key}`)}}</th>
      </template>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableHead',
  props: ['headInfo'],
  data () {
    return {
    }
  }
}
</script>

<style scoped>
button{
  height:40px;
  border:1px solid black;
}
th {
  height: 50px;
  background: #fbfbfb;
  color: #3d3d3d;
  vertical-align: middle;
  font-size:13px;
  white-space: nowrap;
}
.funcHead{
  width:0;
}
</style>
