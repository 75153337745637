<template>
  <div class="box-ui-check">
    <div class="chk-content-wrap" v-for="(chk, index) in checkList" :key="index">
      <input type="checkbox" :id="`srchChk${chk.val}`" :value="chk.val" v-model="selected"/>
      <label :for="`srchChk${chk.val}`">
        <i class="fas fa-check"></i>
        <span>{{chk.text}}</span>
      </label>
    </div>

    <div class="chk-content-wrap srchChkAll-wrap" v-if="useChkAll">
      <input type="checkbox" id="srchChkAll" value="all" v-model="selectAll"/>
      <label :for="`srchChkAll`"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCheck',
  props: ['checkList', 'inSrch', 'useChkAll'],
  data () {
    return {
      selected: []
    }
  },
  computed: {
    selectAll: {
      get () {
        return this.checkList ? this.selected.length === this.checkList.length : false
      },
      set (value) {
        const selected = []

        if (value) {
          this.checkList.forEach(function (chk) {
            selected.push(chk.val)
          })
        }

        this.selected = selected
      }
    }
  }
}

</script>

<style scoped>
.box-ui-check {
  font-size: 0.75em;
  display:flex;
  align-items: center;
}
.chk-content-wrap{
  margin-right:8px;
}
.box-ui-check .chk-content-wrap:last-child{
  margin-right:0;
}
input[type="checkBox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: flex;
  align-items: center;
}
input[type="checkbox"] + label span{
  font-weight: 700;
}
input[type="checkbox"] + label > i {
  border: 2px solid #394357;
  background: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}
input[type="checkbox"]:checked + label > i {
  background: #394357;
}

.srchChkAll-wrap label {
    box-sizing: border-box;
    background: #ea5c52;
    padding: 6px 5.5px;
    font-weight: 700;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border-radius: 6px;
}
.srchChkAll-wrap label:after{
  content:"전체선택";
}
.srchChkAll-wrap input[type="checkbox"]:checked + label{
  background:#83AFE1;
}
.srchChkAll-wrap input[type="checkbox"]:checked + label:after{
  content:"선택해제"
}

</style>
